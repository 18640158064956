<template>
  <div class="c-app">
    <TheSidebar/>
    <div class="c-wrapper">
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <CAlert :class="message ? 'message-on' : 'message-off'" id="containerAlert" :color="message.status" v-html="message.text"></CAlert>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </div>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  },
  data(){
    return {
      message: {}
    }
  },
  mounted(){
    EventBus.$on('showMessage', message => this.showMessage(message));
  },
  methods:{
    showMessage(message){
      this.message = message
      setTimeout(()=>{
        this.message = {}
      }, 3000)
    }
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.message-on{
  opacity: 1;
  transition: 0.5s;
}
.message-off{
  opacity: 0;
  transition: 0.5s;
}
</style>
