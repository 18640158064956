const user = JSON.parse(localStorage.getItem('user'));

let NavDefault = [
  {
    _name: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Vendas',
    to: '/sales',
    icon: 'cil-chart-pie',
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Clientes',
    to: '/clients',
    icon: 'cilPeople',
  },
]

let NavAdmin = []
if(user && (user.roles.filter(r => r === 'ROLE_ADMIN').length > 0)){
  NavAdmin = [
    {
      _name: 'CSidebarNavTitle',
      _children: ['Admin']
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Relatórios',
      to: '/report',
      icon: 'cilCalculator'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Usuários',
      to: '/users',
      icon: 'cil-user'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Subscritos',
      to: '/subscribers',
      icon: 'cil-userFollow'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Preços',
      to: '/prices',
      icon: 'cil-pencil'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Cupons de Desconto',
      to: '/promocodes',
      icon: 'cilDollar'
    }
  ]
}

const NavOptions = NavDefault.concat(NavAdmin)

export default [
  {
    _name: 'CSidebarNav',
    _children: NavOptions
  }
]